import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { PollState } from '@mid-react-common/addins';
import { NOTIFICATION_STATUSES, ShowNotificationProps, StateSetter } from '@mid-react-common/common';
import { VariantFormState, VariantFormStates } from '@mid-react-common/revit-components';
import { downloadVariantLogFile, insertRFA } from 'mid-addin-lib';
import { ProductReleaseError, logError } from 'mid-utils';
import { useCallback, useEffect, useState } from 'react';
import text from 'revit.text.json';

interface UseInsertInConfigureScreenProps {
  currentProductRelease: ProductRelease | undefined;
  selectedRepresentation: string | undefined;
  polledVariant: Variant | null;
  pollState: PollState;
  variantFormState: VariantFormStates;
  selectedCachedVariant: Variant | null;
  postVariant: (tenancyId: string, contentId: string, release: number) => Promise<Variant>;
  startPollingVariantStatus: () => void;
  setVariantFormState: StateSetter<VariantFormStates>;
  setReFetchCachedVariants: StateSetter<boolean>;
  showNotification: (props: ShowNotificationProps) => void;
}

interface UseInsertInConfigureScreenState {
  handleInsertRFA: () => void;
}

const useInsertInConfigureScreen = ({
  currentProductRelease,
  selectedRepresentation,
  polledVariant,
  pollState,
  variantFormState,
  selectedCachedVariant,
  postVariant,
  startPollingVariantStatus,
  setVariantFormState,
  setReFetchCachedVariants,
  showNotification,
}: UseInsertInConfigureScreenProps): UseInsertInConfigureScreenState => {
  const [postVariantTrigger, setPostVariantTrigger] = useState<boolean>(false);

  const handleVariantFailure = useCallback(async () => {
    if (!polledVariant || !currentProductRelease) {
      return;
    }

    try {
      const downloadLocation = await downloadVariantLogFile(
        polledVariant.tenancyId,
        polledVariant.contentId,
        polledVariant.variantId,
        currentProductRelease.name,
      );

      showNotification({
        message: text.successDownloadVariantLog + downloadLocation,
        severity: NOTIFICATION_STATUSES.WARNING,
        // keep the notification alive until user manually closes it (to be able to copy that log path)
        autoDismiss: undefined,
      });
    } catch (downloadFailedVariantLogError) {
      logError(downloadFailedVariantLogError);
      showNotification({
        message: text.failDownloadVariantLog,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [polledVariant, showNotification, currentProductRelease]);

  const insertGeneratedVariantRfa = useCallback(
    async (variant: Variant) => {
      try {
        // Insert RFA
        await insertRFA(variant, selectedRepresentation);
        showNotification({
          message: text.succeedInsert,
          severity: NOTIFICATION_STATUSES.SUCCESS,
        });

        setReFetchCachedVariants(true);
      } catch (err: any) {
        logError(err);
        showNotification({
          message: text.failInsert,
          severity: NOTIFICATION_STATUSES.ERROR,
        });

        await handleVariantFailure();
      }
    },
    [selectedRepresentation, handleVariantFailure, showNotification, setReFetchCachedVariants],
  );

  const handleInsertRFA = useCallback(async () => {
    if (variantFormState === VariantFormState.VARIANT_GENERATED && selectedCachedVariant) {
      return await insertGeneratedVariantRfa(selectedCachedVariant);
    }

    // Post Variant
    try {
      if (!currentProductRelease?.contentId) {
        throw new ProductReleaseError(text.insertFailedMissingContentId, {
          currentProductRelease,
        });
      }

      setVariantFormState(VariantFormState.GENERATING_NEW_VARIANT);
      await postVariant(currentProductRelease.tenancyId, currentProductRelease.contentId, currentProductRelease.release);
      startPollingVariantStatus();
      setVariantFormState(VariantFormState.INSERTING_VARIANT);
      setPostVariantTrigger(true);
    } catch (err) {
      logError(err);
      showNotification({
        message: text.failInsert,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [
    currentProductRelease,
    insertGeneratedVariantRfa,
    postVariant,
    selectedCachedVariant,
    setVariantFormState,
    showNotification,
    startPollingVariantStatus,
    variantFormState,
  ]);

  const pollingVariantAfterInsertRFA = useCallback(async () => {
    if (pollState === PollState.POLLING) {
      setVariantFormState(VariantFormState.GENERATING_NEW_VARIANT);
    }

    if (pollState === PollState.FINISHED) {
      setVariantFormState(VariantFormState.VARIANT_GENERATED);
      setPostVariantTrigger(false);
      if (polledVariant) {
        await insertGeneratedVariantRfa(polledVariant);
      } else {
        showNotification({
          message: text.failInsert,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }

    if (pollState === PollState.ERROR) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);
      setPostVariantTrigger(false);
      showNotification({
        message: text.failGenerate,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [insertGeneratedVariantRfa, pollState, polledVariant, setVariantFormState, showNotification]);

  useEffect(() => {
    if (postVariantTrigger) {
      pollingVariantAfterInsertRFA();
    }
  }, [pollingVariantAfterInsertRFA, postVariantTrigger]);

  return {
    handleInsertRFA,
  };
};

export default useInsertInConfigureScreen;
