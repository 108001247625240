import { FlexContainer } from '@mid-react-common/common';
import CreateIcon from '@mui/icons-material/Create';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const StyledCreateIcon = styled(CreateIcon)`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`;

export const RepresentationEditButton = styled(Button)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.charcoal[700]};
  max-width: ${({ theme }) => `${theme.var.representationEditButtonMaxWidth}px`};
`;

export const RepresentationEditButtonText = styled('span')`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const ProductCustomizationFooterContainer = styled(FlexContainer)`
  margin: ${({ theme }) => `${theme.var.marginBase}px`};
`;
