import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { NOTIFICATION_STATUSES, ShowNotificationProps, StateSetter } from '@mid-react-common/common';
import { ProductReleaseError, logError } from 'mid-utils';
import { useCachedVariantThumbnail } from '../../hooks/useCachedVariantThumbnail';
import { VariantFormState, VariantFormStates } from '../../interfaces/typings';
import text from '../../revit-components.text.json';

export interface ModalState {
  isOpen: boolean;
  dontShowAgain?: boolean;
  dontShowAgainMessage?: string;
  title?: string | JSX.Element;
  message: string | JSX.Element;
  onConfirmCallback?: Function;
  isConfirmCallbackAsync?: boolean;
  onSecondaryConfirmCallback?: Function;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  disableConfirmButton?: boolean;
  secondaryConfirmButtonLabel?: string;
  disableSecondaryConfirmButton?: boolean;
}

interface UseProductCustomizationFooterProps {
  currentProductRelease: ProductRelease | undefined;
  selectedCachedVariant: Variant | null;
  selectedRepresentation: string | undefined;
  setVariantFormState: StateSetter<VariantFormStates>;
  setReFetchCachedVariants: StateSetter<boolean>;
  resetConfigurableProductProperties: () => void;
  setSelectedCachedVariant: (value: React.SetStateAction<Variant | null>) => void;
  dontShowAgain: boolean | undefined;
  setModalState: StateSetter<ModalState>;
  postVariant: (tenancyId: string, contentId: string, release: number) => Promise<Variant>;
  showNotification: (props: ShowNotificationProps) => void;
  setIsFormInitializing: StateSetter<boolean>;
  handleAmplitudeVariantsGenerateEvent: (postedVariant: Variant) => void;
  setPostedVariantIdsList: StateSetter<string[]>;
}

interface UseProductCustomizationFooterState {
  cachedVariantThumbnail: string | undefined;
  handleGenerateNewVariantClick: () => Promise<void>;
  handleResetClick: () => void;
}

const useProductCustomizationFooter = ({
  currentProductRelease,
  selectedCachedVariant,
  selectedRepresentation,
  setVariantFormState,
  setReFetchCachedVariants,
  resetConfigurableProductProperties,
  setSelectedCachedVariant,
  dontShowAgain,
  setModalState,
  postVariant,
  showNotification,
  setIsFormInitializing,
  handleAmplitudeVariantsGenerateEvent,
  setPostedVariantIdsList,
}: UseProductCustomizationFooterProps): UseProductCustomizationFooterState => {
  const { cachedVariantThumbnail, setCachedVariantThumbnail } = useCachedVariantThumbnail({
    currentProductRelease,
    selectedCachedVariant,
    selectedRepresentation,
  });

  const generateNewVariant = async () => {
    try {
      if (!currentProductRelease?.contentId) {
        throw new ProductReleaseError(text.generateFailedProductDoesNotContainContentId, {
          currentProductRelease,
        });
      }
      setVariantFormState(VariantFormState.GENERATING_NEW_VARIANT);
      const postedVariant = await postVariant(
        currentProductRelease.tenancyId,
        currentProductRelease.contentId,
        currentProductRelease.release,
      );

      setPostedVariantIdsList((prevState) => [...prevState, postedVariant.variantId]);
      // calling Amplitude event handler
      handleAmplitudeVariantsGenerateEvent(postedVariant);

      setVariantFormState(VariantFormState.VARIANT_RFA_OUTPUT_PENDING);
      setReFetchCachedVariants(true);

      showNotification({
        message: text.sentForGeneration,
        messageBody: text.sentForGenerationHeadline,
        severity: NOTIFICATION_STATUSES.SUCCESS,
        autoDismiss: true,
      });
    } catch (err) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);

      logError(err);
      showNotification({
        message: text.failGenerate,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const handleGenerateNewVariantClick = async () => {
    if (!dontShowAgain) {
      setModalState({
        isOpen: true,
        title: text.generateNow,
        message: text.generateModalMessage,
        confirmButtonLabel: text.generate,
        cancelButtonLabel: text.dontGenerate,
        dontShowAgainMessage: text.dontShowAgainMessage,
        onConfirmCallback: generateNewVariant,
      });
    } else {
      await generateNewVariant();
    }
  };

  const handleResetClick = (): void => {
    resetConfigurableProductProperties();
    setIsFormInitializing(true);
    setVariantFormState(VariantFormState.DEFAULT_VARIANT);
    setSelectedCachedVariant(null);
    setCachedVariantThumbnail(undefined);
  };

  return {
    cachedVariantThumbnail,
    handleGenerateNewVariantClick,
    handleResetClick,
  };
};

export default useProductCustomizationFooter;
