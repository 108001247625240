import { useBase64Thumbnail } from '@mid-react-common/addins';
import { useEffect, useState } from 'react';

interface UseVariantThumbnailProps {
  tenancyId?: string;
  thumbnail?: string;
  isLoading?: boolean;
}

interface UseVariantThumbnailReturn {
  shouldThumbnailBoxShowLoader: boolean;
  thumbnailInBase64?: string;
  thumbnailError?: string;
}

export const useVariantThumbnail = ({
  tenancyId,
  thumbnail,
  isLoading,
}: UseVariantThumbnailProps): UseVariantThumbnailReturn => {
  const [argsForUseBase64Thumbnail, setArgsForUseBase64Thumbnail] = useState<{
    tenancyId?: string;
    thumbnail?: string;
  }>({});
  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    argsForUseBase64Thumbnail.tenancyId,
    argsForUseBase64Thumbnail.thumbnail,
  );
  useEffect(() => {
    if (tenancyId && thumbnail) {
      setArgsForUseBase64Thumbnail({
        tenancyId,
        thumbnail,
      });
    }
  }, [tenancyId, thumbnail]);

  return {
    shouldThumbnailBoxShowLoader: isLoading || thumbnailLoading,
    thumbnailInBase64,
    thumbnailError,
  };
};
