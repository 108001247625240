export const variantThumbnail = {
  thumbnailLoaderContainer: 'variant-thumbnail-loader-container',
  thumbnailContainer: 'variant-thumbnail-container',
};

export const productCustomizationFooter = {
  generateNewVariantButton: 'product-customization-footer-generate-button',
  resetButton: 'product-customization-footer-reset-button',
  editRepresentationIconButton: 'product-customization-footer-edit-representation-icon-button',
  closeRepresentationIconButton: 'product-customization-footer-close-representation-icon-button',
  representationContainer: 'product-customization-footer-close-representation-container',
};

export const productDetailsTestIds = {
  detailsTab: 'product-details-details-tab',
  variantsTab: 'product-details-variants-tab',
  variantsContainer: 'product-details-variants-container',
  detailsContainer: 'product-details-details-container',
};
