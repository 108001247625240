import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { NOTIFICATION_STATUSES, NotificationContext } from '@mid-react-common/common';
import DataContext from 'context/Data/Data.context';
import NavigationContext from 'context/Navigation/Navigation.context';
import { Screens } from 'context/Navigation/navigationStore';
import { checkAndDownloadProductRulesFromKey, getDesktopApplicationVersionNumber } from 'mid-addin-lib';
import { CustomError, ProductReleaseError, logError } from 'mid-utils';
import { useContext, useState } from 'react';
import text from 'revit.text.json';
import { ampli } from '../../../ampli';

interface useInitialState {
  productReleaseEnhancementWithRulesLoading: boolean;
  handleOpenProductClick: () => void;
}

export const useInitial = (): useInitialState => {
  const { setCurrentScreen } = useContext(NavigationContext);
  const { currentProductRelease, setCurrentProductRelease } = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);

  const [productReleaseEnhancementWithRulesLoading, setProductReleaseEnhancementWithRulesLoading] = useState(false);

  const handleOpenProductClick = async () => {
    try {
      setProductReleaseEnhancementWithRulesLoading(true);
      if (!currentProductRelease) {
        throw new ProductReleaseError(text.newVariantMissingSelectedProductRelease, {
          currentProductRelease,
        });
      }

      // Validate and load rules before opening product
      await checkApplicationVersion(currentProductRelease);
      const productRules = await checkAndDownloadProductRulesFromKey(
        currentProductRelease.tenancyId,
        currentProductRelease.rulesKey,
      );

      // If product has rules downloaded with rulesKey, update rules property
      if (productRules) {
        setCurrentProductRelease({ ...currentProductRelease, rules: productRules });
      }

      // Amplitude event
      ampli.rvtwProductOpen({
        productName: currentProductRelease.name,
        releaseNumber: currentProductRelease.release,
        projectId: currentProductRelease.tenancyId,
        productId: currentProductRelease.contentId,
        hasRules: !!currentProductRelease.rules,
        numberOfInputs: currentProductRelease.inputs.length,
        releaseStatus: currentProductRelease.status,
      });

      // If succeeded go to next screen
      setCurrentScreen(Screens.PRODUCT_CUSTOMIZATION);
    } catch (err) {
      if (err instanceof CustomError) {
        logError(err);
        showNotification({
          message: err.message,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        logError(err);
        showNotification({
          message: text.failedToLoadProduct,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    } finally {
      setProductReleaseEnhancementWithRulesLoading(false);
    }
  };

  const checkApplicationVersion = async (currentProductRelease: ProductRelease): Promise<void> => {
    const applicationVersionNumber = await getDesktopApplicationVersionNumber();
    if (
      applicationVersionNumber !== undefined &&
      parseInt(currentProductRelease.context.engine.version) > parseInt(applicationVersionNumber)
    ) {
      throw new ProductReleaseError(text.incompatibleProduct, {
        currentProductRelease,
      });
    }
  };

  return {
    productReleaseEnhancementWithRulesLoading,
    handleOpenProductClick,
  };
};
